<template>
  <div v-if="canViewSettings">
    <BackofficeContainer>
      <div v-if="!isCoordinationCenter">
        <b-card>
          <!-- Seetings form -->
          <ValidationObserver #default="{ handleSubmit }" ref="refFormObserver">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <div class="d-flex flex-wrap justify-content-between my-2">
                <h4>{{ $t('backoffice.settings.subtitle') }}</h4>
                <div class="d-flex">
                  <LanguageSelector 
                    class="mr-2" 
                    @selectLanguage="(language) => (selectedLanguage = language)" 
                  />
                  <b-button variant="primary" :disabled="isDisabled" @click="onSubmit">
                    {{ $t('form.actions.save') }}
                  </b-button>
                </div>
              </div>
              <b-row>
                <b-col lg="6">
                  <!-- Name -->
                  <ValidationProvider #default="validationContext" name=" " rules="required">
                    <b-form-group>
                      <label for="name" class="font-weight-bold">
                        {{ $t('backoffice.settings.community-name') }}*
                        <feather-icon
                          v-b-tooltip.hover.bottom
                          :title="$t('tooltips.community-name')"
                          icon="InfoIcon"
                          size="14"
                          class="text-primary mt-n25"
                        />
                      </label>
                      <b-form-input
                        id="name"
                        v-model="form.name[selectedLanguage]"
                        type="text"
                        :placeholder="$t('form.type-placeholder')"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback class="text-danger">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Headline -->
                  <ValidationProvider name=" " rules="max:100">
                    <b-form-group>
                      <label for="headline" class="font-weight-bold"> {{ $t('backoffice.settings.headline') }}</label>
                      <b-form-input
                        id="headline"
                        v-model="form.headline[selectedLanguage]"
                        type="text"
                        :placeholder="$t('form.type-placeholder')"
                      />
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Slug -->
                  <ValidationProvider
                    name=" " 
                    rules="required|max:255">
                    <!-- #default="validationContext"  -->
                    <b-form-group>
                      <label for="slug" class="font-weight-bold">
                        {{ $t('backoffice.settings.slug') }}*
                        <feather-icon
                          v-b-tooltip.hover.bottom
                          :title="$t('tooltips.slug')"
                          icon="InfoIcon"
                          size="14"
                          class="text-primary mt-n25"
                        />
                      </label>
                      <b-form-input
                        :disabled="isHardcodedSlug"
                        id="slug"
                        v-model="form.slug"
                        type="text"
                        :placeholder="$t('form.type-placeholder')"
                        :state="availableSlug"
                        @input="getSlugValidator()"
                      />
                      <b-form-invalid-feedback v-if="!availableSlug" class="text-danger">
                        {{ $t('settings.error-message') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Subdomain -->
                  <b-form-group v-if="!isSubcommunity">
                    <label for="subdomain" class="font-weight-bold"> {{ $t('backoffice.settings.subdomain') }}</label>
                    <b-form-input
                      id="subdomain"
                      v-model="form.subdomain"
                      type="text"
                      :placeholder="$t('form.type-placeholder')"
                      :state="availableSubdomain"
                      @input="getSubdomainValidator()"
                    />
                    <b-form-invalid-feedback v-if="!availableSubdomain" class="text-danger">
                      {{ $t('settings.error-message') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- Visibility -->
                  <ValidationProvider #default="validationContext" name=" " rules="required">
                    <b-form-group v-if="!isSubcommunity && form.accessibility !== 3">
                      <label for="visibility" class="font-weight-bold">
                        {{ $t('backoffice.settings.visibility') }}*
                        <feather-icon
                          v-b-tooltip.hover.bottom
                          :title="$t('tooltips.vissibility')"
                          icon="InfoIcon"
                          size="14"
                          class="text-primary mt-n25"
                        />
                      </label>
                      <v-select
                        id="visibility"
                        v-model="form.visibility"
                        :placeholder="$t('form.select-placeholder')"
                        :options="visibilityOptions"
                        :clearable="false"
                        :searchable="false"
                        :state="getValidationState(validationContext)"
                        @input="visibility"
                      />
                      <b-form-invalid-feedback class="text-danger">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Accesibility -->
                  <ValidationProvider #default="validationContext" name=" " rules="required">
                    <b-form-group v-if="form.accessibility !== 3 && !currentCollective.isSection && currentCollective.mainType != 'Workspace'">
                      <label for="accessibility" class="font-weight-bold">
                        {{ $t('backoffice.settings.accessibility') }}*
                        <feather-icon
                          v-b-tooltip.hover.bottom
                          :title="$t('tooltips.accessibility')"
                          icon="InfoIcon"
                          size="14"
                          class="text-primary mt-n25"
                        />
                      </label>
                      <v-select
                        id="accessibility"
                        v-model="accessibilityOption"
                        :searchable="false"
                        :reduce="(accessibility) => accessibility.code"
                        label="accessibility"
                        :placeholder="$t('form.select-placeholder')"
                        :options="accessibilityOptions"
                        :clearable="false"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback class="text-danger">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <!-- Modality -->
                  <b-form-group v-if="['Event', 'Project', 'Course'].includes(currentCollective.mainType)">
                    <label for="modality" class="font-weight-bold">{{ $t('events.actions.modality-options') }}</label>
                    <v-select
                      id="modality"
                      v-model="modalityOption"
                      :options="modalityOptions"
                      :clearable="false"
                      :reduce="(name) => name.code"
                      :searchable="false"
                      label="name"
                    />
                  </b-form-group>
                  <!-- Status -->
                  <b-form-group v-if="currentCollective.mainType === 'Challenge' || currentCollective.mainType === 'Project'" class="flex-grow-1">
                    <label for="status" class="font-weight-bold">
                      {{ $t("tasks.status.status") }}:
                    </label>
                    <v-select
                      v-model="form.communityStatus"
                      name="status"
                      :searchable="false"
                      label="name"
                      :clearable="false"
                      :options="typeOptions"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <!-- Description -->
                  <b-form-group>
                    <label for="description" class="font-weight-bold"> {{ $t('backoffice.settings.description') }}</label>
                    <quill-editor
                      id="description"
                      v-model="form.description[selectedLanguage]"
                      rows="15"
                      class="quillE"
                      :placeholder="$t('form.select-placeholder')"
                    />
                  </b-form-group>
                  <span class="d-flex flex-wrap">
                    <!-- Published -->
                    <div class="d-flex flex-wrap mr-2">
                      <b-form-checkbox
                        v-model="published"
                        switch
                        class="mb-1 ml-50"
                      />
                      <label for="published" class="font-weight-bold mt-25">
                        {{ $t('settings.published',{item: $t(`collectives.items.${mainType}`)}) }}
                        <feather-icon
                          v-b-tooltip.hover.bottom
                          :title="$t('tooltips.publish')"
                          icon="InfoIcon"
                          size="14"
                          class="text-primary mt-n25"
                        />
                      </label>
                    </div>
                    <!-- Bypass -->
                    <!--div v-if="isSubcommunity" class="d-flex">
                      <b-form-checkbox
                        v-model="bypass"
                        switch
                        class="mb-1 ml-50"
                      />
                      <label for="published" class="font-weight-bold mt-25"> {{ $t('bypass') }}</label>
                    </div-->
                  </span>
                  <hr>
                  <div>
                    <p class="mb-75 font-weight-bold">
                      {{ $t('backoffice.settings.code') }}: <span class="text-underline">{{ hexToDecimal }}</span>
                      <span class="ml-50 pointer text-primary mt-2" @click="handleClipboardCopied()">
                        <feather-icon icon="CopyIcon" size="18" class="text-primary mr-25" />
                      </span>
                    </p>
                    <p class="font-weight-bold">
                      {{ $t('header.action.created-by') }} <span v-if="form.createdByMember">{{ $t('header.action.by',{ creator: `${form.createdByMember.name} ${form.createdByMember.surname}`}) }}</span> <span v-if="form.createdAt">{{ $t('header.action.date-creation', { date: timestampToTime(form.createdAt)}) }}</span>
                    </p>
                    <b-alert
                      :show="isCopied"
                      dismissible
                      variant="primary"
                      @dismissed="isCopied=false"
                    >
                      {{ $t('share.copied-message') }}
                    </b-alert>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-card>
      </div>

      <!-- MainData -->
      <MainData />

      <!-- Images -->
      <Images />

      <!-- Dates -->
      <Dates v-if="(!isCoordinationCenter && currentCollective.mainType != 'Organization')" />
      
      <!-- Capacity -->
      <b-card v-if="['Event', 'Course'].includes(currentCollective.mainType)">
        <div class="d-flex justify-content-between flex-wrap my-1">
          <h4>{{ $t('events.subscription.action.capacity') }}</h4>
          <div class="d-flex">
            <b-button variant="primary" class="ml-1" @click="handleCapacity()">
              {{ $t('form.actions.save') }}
            </b-button>
          </div>
        </div>
        <b-row>
          <b-col v-if="modalityOption === 0 || modalityOption === 1">
            <!-- Capacity -->
            <!-- Online -->
            <b-form-group label="Online" label-for="capacity" class="font-weight-bold">
              <b-form-input
                id="capacity"
                v-model="form.capacityOnline"
                type="number"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="modalityOption === 1 || modalityOption === 2">
            <!-- On-site -->
            <b-form-group :label="$t('community-others.on-site')" label-for="capacity" class="font-weight-bold">
              <b-form-input
                id="capacity"
                v-model="form.capacityOnsite"
                type="number"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!-- Event Link -->
      <EventLink
        v-if="isEvent"
        @internal="handleIntern"
        @registrationURL="handleRegistration"
        @joinURL="handleJoin"
      />
      
      <!-- Prices -->
      <PricesCard v-if="canEditPrices"/>

      <!-- Languages -->
      <Languages v-if="!isSubcommunity" />

      <!-- Menu type -->
      <MenuType />

      <!-- Styles: colors -->
      <Styles />

      <!-- ChatBot -->
      <Chatbot v-if="canEditChatSettings" />
      
      <!-- Classifiers -->
      <Classifiers
        :organization="currentCollective"
        :morph-type="currentCollective.mainType.toLowerCase()"
        :morph-key="currentCollective.key"
        :classifiers="currentCollective.classifiers"
        :model-type="currentCollective.mainType"
        :forSettings="true"
        :backoffice="true"
      />

      <!-- Custom fields -->
      <CustomFields 
        :item="currentCollective" 
        :morph-type="currentCollective.mainType.toLowerCase()" 
      />

      <!-- Danger Zone -->
      <DangerZone 
        v-if="isOwner || isSubcommunity" 
        :type="mainType" 
      />
    </BackofficeContainer>
  </div>

  <!-- Placeholder - no permissions -->
  <b-card v-else>
    <b-alert variant="danger" show>
      {{ $t('no-permisions.message') }}
    </b-alert>
    <b-alert variant="yellow" show>
      {{ 'Please check the menu on the left to see what content and features you have access.' }}
    </b-alert>
    <!-- <div>
      <span>
        {{ 'Please check the menu on the left to see what content and features you have access.' }}
      </span>
    </div> -->
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import Service from '@/config/service-identifiers';
import FileTypes from '@/@core/constants/FileTypes';
import { validatorWithoutSpecialCharacters } from '@/@core/utils/validations/validators';
import { quillEditor } from 'vue-quill-editor';
import { checkPermissionsForStaffs } from '@/@core/utils/roles-utils';
import ErrorPlaceholder from '@/assets/images/placeholders/light/acceso_restringido.svg';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BackofficeContainer,
    quillEditor,
    EventLink: () => import('@/backoffice/components/EventLink.vue' /* webpackChunkName: "EventLink" */),
    Images: () => import('@/backoffice/components/Images.vue' /* webpackChunkName: "Images" */),
    Styles: () => import('@/backoffice/components/Style.vue' /* webpackChunkName: "Styles" */),
    MenuType: () => import('@/backoffice/components/Menu.vue' /* webpackChunkName: "MenuType" */),
    Dates: () => import('@/backoffice/components/Dates.vue' /* webpackChunkName: "Dates" */),
    Languages: () => import('@/backoffice/components/Languages.vue' /* webpackChunkName: "Languages" */),
    Chatbot: () => import('@/backoffice/components/Chatbot.vue' /* webpackChunkName: "Chatbot" */),
    MainData: () => import('@/backoffice/components/mainData.vue' /* webpackChunkName: "MainData" */),
    DangerZone: () => import('@/backoffice/components/dangerZone.vue' /* webpackChunkName: "DangerZone" */),
    LanguageSelector: () => import('@core/components/modal/LanguageSelector.vue' /* webpackChunkName: "LanguageSelector" */),
    Classifiers: () => import('@/views/organization-settings/components/Classifiers.vue' /* webpackChunkName: "Classifiers" */),
    CustomFields: () => import('@/views/organization-settings/components/CustomFields.vue' /* webpackChunkName: "CustomFields" */),
    PricesCard: () => import('@/backoffice/components/PricesCard.vue' /* webpackChunkName: "PricesCard" */),
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        name: '',
        headline: {},
        description: {},
        slug: '',
        subdomain: '',
        website: '',
        visibility: '',
        visibilityAux: '',
        accessibility: '',
        accessibilityAux: '',
        type: '',
        timeZone: '',
        modality: '',
        joinURL: null,
        registrationURL: null,
        capacityOnline: null,
        capacityOnsite: null,
        isCapacity: false,
        bypass: false,
        communityStatus: null,
      },
      required,
      max,
      isCopied: false,
      isLinkSectionActive: false,
      isStaff: false,
      availableSlug: null,
      availableSubdomain: null,
      accessibilityOption: 0,
      published: false,
      modalityOption: '',
      selectedLanguage: '',
      modalityOptions: [
        { name: this.$t('events.event-details.online'), code: 0 },
        { name: this.$t('events.event-details.on-site'), code: 2 },
        { name: this.$t('events.event-details.hybrid'), code: 1 },
      ],
      accessibilityOptions: [
        { code: 2, accessibility: this.$t('events.actions.accessibility.invitation') },
        { code: 1, accessibility: this.$t('events.actions.accessibility.with-request') },
        { code: 0, accessibility: this.$t('events.actions.accessibility.without-request') },
      ],
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    canEditChatSettings() {
      return true;
    },
    canEditPrices() {
      return ['Event', 'Course'].includes(this.currentCollective.mainType);
    },
    isOwner() {
      return this.$store.getters.loggedMemberRoles?.find(({ tagName }) => tagName === 'owner');
    },
    imgUrl() {
      return ErrorPlaceholder;
    },
    canManageSettings() {
      return checkPermissionsForStaffs('manage', 'settings', this.loggedMemberRoles, this.collective);
    },
    canViewSettings() {
      return this.canManageSettings || this.isOwner || checkPermissionsForStaffs('view', 'settings', this.loggedMemberRoles, this.collective);
    },

    typeOptions() {
      return [
        { name: this.$t('backoffice.feedback.status.without-status'), code: null },
        { name: this.$t('backoffice.feedback.status.to-do'), code: 'egPTvvKTSW' },
        { name: this.$t('backoffice.feedback.status.in-progress'), code: 'G4CdL1y09v' },
        { name: this.$t('backoffice.feedback.status.paused'), code: 'G4CdL1y09w' },
        { name: this.$t('backoffice.feedback.status.done'), code: 'Zj0k3D2HlD' },
      ];
    },
    communityError() {
      return this.$store.getters.communityError;
    },
    FileTypes() {
      return FileTypes;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    visibilityOptions() {
      return [this.$t('backoffice.settings.visibility-true'), this.$t('backoffice.settings.visibility-false')];
    },
    linkLabelsOptions() {
      return ActiveLinksLabels;
    },
    isSaveEnabled() {
      return (
        this.form.name
        && this.form.slug
        && (this.form.accessibilityAux || this.form.accessibility)
        && (this.form.visibilityAux || this.form.visibility)
      );
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isEvent() {
      return this.currentCollective.mainType === 'Event';
    },
    isDisabled() {
      return (
        !this.form.name
        || !this.form.slug
        || this.availableSlug === false
        || this.availableSubdomain === false
        || !this.form.visibility
      );
    },
    isSameSlug() {
      return this.availableSlug === false && this.form.slug === this.currentCollective.slug;
    },
    isHardcodedSlug() {
      const isHardcodedSlug = this.currentCollective.slug === process.env.VUE_APP_MAIN_SPACE_SLUG;
      console.log('isHardcodedSlug:', isHardcodedSlug);
      return isHardcodedSlug || process.env.VUE_APP_MAIN_SPACE_SLUG === 'nexos';
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isCoordinationCenter() {
      return this.$route.params.communityId.includes('coordination-center');
    },
    mainData() {
      return this.$store.getters.mainData.unpaginated[0];
    },
    mainType() {
      return this.mainData?.type || 'Undefined';
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    hexToDecimal() {
      const hex = parseInt(this.collective?.key, 16);
      return hex.toString().substr(0, 5);
    },
  },
  watch: {
    modalityOption() {
      if (this.modalityOption == 0 || !this.modalityOption) {
        if (this.currentCollective.occupation != null) {
          this.form.capacityOnline = this.currentCollective.occupation?.online;
        }
      }
      if (this.modalityOption == 2) {
        if (this.currentCollective.occupation != null) {
          this.form.capacityOnline = null;
          this.form.capacityOnsite = this.currentCollective.occupation['on-site'];
        }
      }
      if (this.modalityOption == 1) {
        if (this.currentCollective.occupation != null) {
          this.form.capacityOnline = this.currentCollective.occupation.online;
          this.form.capacityOnsite = this.currentCollective.occupation['on-site'];
        }
      }
    },
  },
  async created() {
    await this.getMainData();
    this.isStaff = this.currentCollective.isStaff;
    this.selectedLanguage = this.locale;
    this.form = { ...this.currentCollective };
    if (typeof this.currentCollective.description === 'string' || this.currentCollective.description === null) {
      this.form.description = {};
      this.currentCollective.availableLanguages.forEach((language) => {
        this.form.description[language] = this.currentCollective?.description ? this.currentCollective?.description : '';
      });
    }
    if (typeof this.currentCollective.headline === 'string' || this.currentCollective.headline === null) {
      this.form.headline = {};
      this.currentCollective.availableLanguages.forEach((language) => {
        this.form.headline[language] = this.currentCollective.headline ? this.currentCollective.headline : '';
      });
    }
    if (typeof this.currentCollective.name === 'string' || this.currentCollective.name === null) {
      this.form.name = {};
      this.currentCollective.availableLanguages.forEach((language) => {
        this.form.name[language] = this.currentCollective.name ? this.currentCollective.name : '';
      });
    }
    this.form.website = this.currentCollective.landing;
    this.links = this.currentCollective.links;
    this.accessibilityOption = this.form.accessibility;
    this.form.primaryColor = this.primaryColor;
    this.form.secondaryColor = this.secondaryColor;
    if (this.currentCollective.communityStatus == null) {
      this.form.communityStatus = this.typeOptions[0];
    }
    this.initialVisibility();

    this.published = this.currentCollective.isPublished;
    // Bypass
    if (this.isSubcommunity) {
      this.bypass = this.currentCollective?.bypass;
    }
    // Modality
    if (['Event', 'Project', 'Course'].includes(this.currentCollective.mainType)) {
      this.modalityOption = parseInt(this.currentCollective.modalityIndex) || 0;
    }
    if (this.modalityOption == 0 || !this.modalityOption) {
      this.form.capacityOnline = this.currentCollective.occupation?.online;
    }
    if (this.modalityOption == 2) {
      if (this.currentCollective.occupation) {
        this.form.capacityOnsite = this.currentCollective.occupation['on-site'];
      }
    }
    if (this.modalityOption == 1) {
      if (this.currentCollective.occupation) {
        this.form.capacityOnline = this.currentCollective.occupation?.online;
        this.form.capacityOnsite = this.currentCollective.occupation['on-site'];
      }
    }
    // Status

    switch (this.currentCollective?.communityStatus?.key) {
      case ('egPTvvKTSW'):
        this.form.communityStatus = this.typeOptions[1];
        break;
      case ('G4CdL1y09v'):
        this.form.communityStatus = this.typeOptions[2];
        break;
      case ('G4CdL1y09w'):
        this.form.communityStatus = this.typeOptions[3];
        break;
      case ('Zj0k3D2HlD'):
        this.form.communityStatus = this.typeOptions[4];
        break;
      default:
        this.form.communityStatus = this.typeOptions[0];
        break;
    }
  },
  methods: {
    initialVisibility() {
      switch (this.currentCollective.visibility) {
        case false:
          this.form.visibility = this.visibilityOptions[1];
          return;
        case true:
          this.form.visibility = this.visibilityOptions[0];
      }
    },
    visibility() {
      switch (this.form.visibility) {
        case this.visibilityOptions[1]:
          this.form.visibilityAux = false;
          return;
        case this.visibilityOptions[0]:
          this.form.visibilityAux = true;
      }
    },
    async onSubmit() {
      const currentSlug = this.currentCollective.slug;
      const currentSubdomain = this.currentCollective.subdomain;
      let settings = {};
      console.log('this.isCapacity', this.isCapacity);
      if (this.isCapacity) {
        settings = {
          ...(['Event', 'Project', 'Challenge', 'Course'].includes(this.currentCollective.mainType) ? {
            capacity: {
              online: parseInt(this.form.capacityOnline, 10),
              'on-site': parseInt(this.form.capacityOnsite, 10),
            },
          } : ''),
        };
      } else {
        const hasDescription = Object.values(this.form.description).some((item) => typeof item !== 'object');
        settings = {
          name: this.form.name,
          headline: this.form.headline,
          description: hasDescription ? this.form.description : '',
          slug: this.form.slug,
          subdomain: this.form?.subdomain,
          deleteSubdomain: this.form?.subdomain !== currentSubdomain && this.form?.subdomain === '',
          website: this.form.website,
          visibility: this.form.visibilityAux,
          ...(this.isSubcommunity ? { bypass: this.bypass } : ''),
          ...(this.published != this.currentCollective.isPublished ? { switchPublish: true } : ''),
          accessibility: this.accessibilityOption,
          ...(['Event', 'Project', 'Course'].includes(this.currentCollective.mainType) ? {
            modality: this.modalityOption,
          } : ''),
          statusKey: this.form.communityStatus.code,
          deleteStatus: this.form.communityStatus.code == null && this.currentCollective.communityStatus != null,
        };
        if (['Event', 'Project', 'Challenge', 'Course'].includes(this.currentCollective.mainType)) {
          const linksNames = ['joinURL', 'registrationURL'];
          for (const link of linksNames) {
            const nameLink = this.form[link] ? { name: link, value: this.form[link] } : { name: `${link}Deleted`, value: true };
            settings[nameLink.name] = nameLink.value;
          }
        }
      }
      console.log('settings', settings)
      try {
        const response = await this.$store.dispatch('editCommunity', {
          settings,
        });
        this.notifySuccess(this.$t('backoffice.settings.messages.success'));
        if (this.form.slug !== currentSlug || (this.form.subdomain !== currentSubdomain)) {
          window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
            '{subdomain}',
            response.subdomain || 'app',
          ).replace('{slug}', response.slug)}/backoffice/settings`;
        }
        return response.data;
      } catch {
        if (this.communityError?.slugInUse) {
          this.notifyError(this.$t('backoffice.settings.messages.slug-error'));
        } else if (this.communityError?.onlineCapacityComplete || this.communityError?.onsiteCapacityComplete || this.communityError?.completeCapacity) {
          this.notifyError(this.$t('backoffice.settings.messages.capacity-error'));
        } else {
          this.notifyError(this.$t('backoffice.settings.messages.error'));
        }
      }
    },
    getSubdomainValidator() {
      if (!validatorWithoutSpecialCharacters(this.form.subdomain)) {
        this.availableSubdomain = false;
      } else {
        this.availableSubdomain = true;
      }
    },
    async getSlugValidator() {
      if (!validatorWithoutSpecialCharacters(this.form.slug)) {
        this.availableSlug = false;
        return;
      }
      try {
        await this.$store.$service[Service.BackendClient].get('communities', {
          params: {
            communitySlug: this.form.slug,
          },
        });
        // If the user has touched the input, it will return false because his slug already exists, but you must let him save because it is his
        this.form.slug === this.currentCollective.slug ? (this.availableSlug = true) : (this.availableSlug = false); // If the slug exists, it cannot be selected
      } catch (e) {
        if (e.toString().indexOf('404') > -1) {
          this.availableSlug = true;
        } else {
          this.availableSlug = false;
        }
      }
    },
    handleWebsite(website) {
      this.form.website = website;
      this.onSubmit();
    },
    handleRegistration(registration) {
      console.log('handleRegistration', registration);
      this.form.registrationURL = registration;
      this.form.joinURL = null;
      this.onSubmit();
    },
    handleJoin(join) {
      console.log('handleJoin', join);
      this.form.joinURL = join;
      this.form.registrationURL = null;
      this.onSubmit();
    },
    handleIntern() {
      console.log('handleIntern');
      this.form.joinURL = null;
      this.form.registrationURL = null;
      this.onSubmit();
    },
    async togglePublish() {
      await this.$store.dispatch('editItem', {
        item: {
          itemType: 'communities/isPublished',
          customName: 'community',
        },
      });
    },
    async getMainData() {
      await this.$store.dispatch('getItems', {
        itemType: 'communities/mainData',
        customName: 'mainData',
        page: 1,
        perPage: 15,
      });
    },
    async handleCapacity() {
      this.isCapacity = true;
      await this.onSubmit();
      this.isCapacity = false;
    },
    timestampToTime(timestamp) {
      return new Date(timestamp).toLocaleDateString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
    handleClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.hexToDecimal);
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
.close-icon {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -10px;
}
.ql-container {
    height: 300px !important;
}
.alert-yellow {
  background-color: rgb(255, 200, 0);
  color: black; /* Adjust text color as needed */
  border-color: hsla(calc((0deg + 359.6deg) / 2), calc((0% + 78.125%) / 2), calc((100% + 62.3529411765%) / 2), calc((1 + 1) / 2));
}
</style>
